<template>
  <div>
    <v-row>
      <v-col
        cols="4"
      >
        <v-card
          class="overflow-hidden mb-5"
        >
          <v-card-title class="d-flex justify-space-between">
            <h4>
              Nouvel e-mail
            </h4>
            <v-icon
              top
              right
            >
              {{ icons.mdiEmailPlus }}
            </v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            Envoyer un nouvel e-mail libre
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              block
              color="primary"
              class="mb-5"
              @click="newEmailDialog = true"
            >
              Nouvel e-mail
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <new-email-dialog
      :dialog="newEmailDialog"
      @closeDialog="newEmailDialog = false"
    ></new-email-dialog>
  </div>
</template>

<script>
import { mdiEmailPlus } from '@mdi/js'

import newEmailDialog from '@/components/NewEmailDialog.vue'

export default {
  components: {
    newEmailDialog,
  },
  data() {
    return {
      loading: true,
      newEmailDialog: false,
      icons: {
        mdiEmailPlus,
      },
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>

</style>
